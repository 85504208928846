import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Newsletter from "../Mailchimp/Newsletter";
import ForesHill from "../../assets/images/footer/foresthill.png";
import Lesli from "../..//assets/images/footer/team/lesli_gaynor_avatar.png";
import Parimal from "../..//assets/images/footer/team/parimal_gosai_avatar.png";
library.add(fab);

//year
const d = new Date();
const year = d.getFullYear();

const FooterStyles = styled.div`
  padding: 75px 0 150px 0;

  .menu {
    grid-column-start: 1;
    grid-column-end: 17;
    @media (min-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 6;
    }
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    .menulist {
      margin-right: 75px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          display: block;
          margin-bottom: 10px;
        }
      }
      .socials {
        ul {
          li {
            list-style: none;
            display: inline-block;
            margin-bottom: 10px;
            margin-right: 16px;
          }
        }
      }
    }
    .menu1 {
    }
    .menu2 {
    }
    .menu3 {
    }
  }

  .secondmenu {
    grid-column-start: 1;
    grid-column-end: 17;
    margin-top: 50px;
    @media (min-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 17;
      margin-top: 0;
    }
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .teamlist {
      margin-right: 75px;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin-bottom: 10px;
          display: inline-block;
          margin-right: 24px;
          .teammate {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-width: 50px;
              margin-right: 16px;
            }

            p {
              font-weight: 700;

              span {
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  .newsletter {
    grid-column-start: 1;
    grid-column-end: 17;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 50px;
    @media (min-width: 768px) {
      grid-column-start: 6;
      grid-column-end: 17;
      justify-content: flex-end;
      margin-top: 0;
    }

    .newsletterwrap {
      text-align: left;
    }
  }

  .broker {
    grid-column-start: 1;
    grid-column-end: 17;
    @media (min-width: 768px) {
      grid-column-start: 1;
      grid-column-end: 7;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
    .logowrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 100px;
      }
      .address {
        font-size: 11px;
        margin-left: 20px;
      }
    }
  }

  .company {
    grid-column-start: 1;
    grid-column-end: 17;
    @media (min-width: 768px) {
      grid-column-start: 7;
      grid-column-end: 17;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @media (min-width: 450px) {
        justify-content: flex-end;
      }
      margin-top: 50px;
    }

    margin-top: 50px;
    ul {
      list-style: none;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        margin-left: 0px;
        margin-right: 15px;
        @media (min-width: 768px) {
          margin-left: 15px;
          margin-right: 0px;
        }
        font-size: 14px;
      }
    }
  }
`;

export default function Footer() {
  return (
    <>
      <FooterStyles className="grid16">
        <div className="menu">
          <div className="menu1 menulist">
            <h3>Husmates</h3>
            <ul>
              <li>
                <Link to="https://husmates.com/about">About</Link>
              </li>
              <li>
                <Link to="https://husmates.com/co-ownership">Co-Ownership</Link>
              </li>
              <li>
                <Link to="https://husmates.com/co-ownership">How It Works</Link>
              </li>
              {/* <li>
                <a href="https://beta.husmates.com/login">Log In</a>
              </li>
              <li>
                <a href="https://beta.husmates.com/signup">Sign Up</a>
              </li> */}
              <li> </li>
            </ul>
          </div>

          <div className="menu2 menulist">
            <h3>Connect </h3>
            <ul>
              <li>
                <div className="socials">
                  <ul>
                    <li>
                      <a target="_blank" href="https://facebook.com/husmates">
                        <FontAwesomeIcon
                          className="fa-lg"
                          icon={["fab", "facebook"]}
                        />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://instagram.com/husmates">
                        <FontAwesomeIcon
                          className="fa-lg"
                          icon={["fab", "instagram"]}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="newsletter">
          <div className="newsletterwrap">
            <h3>Subscribe To the Husmates Newsletter</h3>
            <Newsletter />
          </div>
        </div>

        <div className="secondmenu">
          <div className="menu1 teamlist">
            <h3>Our Team</h3>
            <ul>
              <li>
                <div className="teammate">
                  <img src={Lesli} alt="" />
                  <p>
                    Lesli Gaynor
                    <br></br>
                    <span>Sales Representative</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="teammate">
                  <img src={Parimal} alt="" />
                  <p>
                    Parimal Gosai
                    <br></br>
                    <span>Sales Representative</span>
                  </p>
                </div>
              </li>
              <li> </li>
            </ul>
          </div>
        </div>

        <hr />
        <div className="broker">
          <div className="logowrapper">
            {" "}
            <div className="logo">
              {" "}
              <img src={ForesHill} alt="" />
            </div>
            <div className="address">
              Forest Hill Real Estate Brokerage<br></br>
              295 Harbord St., <br></br>
              Toronto, ON M6G 1G7 <br></br>
            </div>
          </div>
        </div>
        <div className="company">
          <ul>
            <li>
              <Link to="/terms">Terms & Conditions</Link>
            </li>

            <li>© Husmates, {year}</li>
          </ul>
        </div>
      </FooterStyles>
    </>
  );
}
